import { Component, OnInit } from "@angular/core";
import { GuardtourService } from "../../services/guardtour.service";
import { Device } from "../../models/guardtour";

@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.css"]
})
export class DevicesComponent implements OnInit {
  devices: Device[];
  editState1: boolean = false;
  editState2: boolean = false;
  deviceToEdit: Device;
  lat: number = 55;
  lng: number = -4;

  constructor(private guardtourService: GuardtourService) {}

  ngOnInit() {


    
    this.guardtourService.getDevices().subscribe(devices => {
      this.devices = devices;
      console.log('devices..... ', devices)
    });
  }

  deleteDevice(event, device: Device) {
    this.clearState();
    this.guardtourService.deleteDevice(device);
  }

  editDevice1(event, device: Device) {
    this.editState1 = true;
    this.deviceToEdit = device;
  }

  editDevice2(event, device: Device) {
    this.editState2 = true;
    this.deviceToEdit = device;
  }

  updateDevice(device: Device) {
    this.guardtourService.updateDevice(device);
    this.clearState();
  }

  clearState() {
    this.editState1 = false;
    this.editState2 = false;
    this.deviceToEdit = null;
  }
}
