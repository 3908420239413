import { Component, OnInit } from '@angular/core';
import { GuardtourService } from "../../services/guardtour.service";
import { SlaAudit } from "../../models/guardtour";

@Component({
  selector: 'app-sla-report',
  templateUrl: './sla-report.component.html',
  styleUrls: ['./sla-report.component.css']
})
export class SlaReportComponent implements OnInit {


  slaHistory: SlaAudit[];
  currentDate: any


  editState1: boolean = false;
  editState2: boolean = false;
  slaItemToEdit: SlaAudit;




  // dateValue : Date

  // slaHistory: any

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {

  }


  
  deleteSlaItem(event, slaItem: SlaAudit) {
    this.clearState();
    this.guardtourService.deleteSlaItem(slaItem);
  }


  editSlaItem1(event, slaItem: SlaAudit) {
    this.editState1 = true;
    this.slaItemToEdit = slaItem;
  }

  editSlaItem2(event, slaItem: SlaAudit) {
    this.editState2 = true;
    this.slaItemToEdit = slaItem;
  }

  updateSlaItem(dateValue, slaItem: SlaAudit) {
    this.guardtourService.updateSlaItem( slaItem);
    this.clearState();
  }

  getSlaHistory(dateValue) {
    if (dateValue == null) {
      dateValue = new Date();
    }

    //let dateFormat = require('dateformat');
    this.currentDate = new Date().toISOString()

    // this.slaHistory = [
    //   { 'id': 'werty1', 'sla_target': 111, 'sla_actual': 11 },
    //   { 'id': 'werty2', 'sla_target': 222, 'sla_actual': 22 },
    //   { 'id': 'werty3', 'sla_target': 333, 'sla_actual': 33 }
    // ]
    this.slaHistory = this.guardtourService.getSlaHistory(dateValue)
    return this.slaHistory
  }

  // getDate(dateValue?) {
  //   if (dateValue == null) {
  //     const dateValue = new Date();
  //   }
  //  return dateValue
  // }

  clearState() {
    this.editState1 = false;
    this.editState2 = false;
    this.slaItemToEdit = null;
  }

}
