import { Component, OnInit } from '@angular/core';
// import { latLng, tileLayer } from 'leaflet';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})


export class MapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // options = {
  //   layers: [
  //     tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {foo: 'bar'})
  //     // tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
  //     //   maxZoom: 20,
  //     //   subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  //     //   detectRetina: true
  //     // })
  //   ],
  //   zoom: 7,
  //   center: latLng([ 55, -4 ])
  // };


 


}





