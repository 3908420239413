import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service"; // see https://alligator.io/angular/firebase-authentication-angularfire2/

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})

export class RegisterComponent implements OnInit {
  email: string;
  password: string;

  constructor(public authService: AuthService) {}

  ngOnInit() {}

  signup() {
    this.authService.signup(this.email, this.password);
    this.email = this.password = '';
  }

  login() {
    this.authService.login(this.email, this.password);
    this.email = this.password = '';    
  }

  logout() {
    this.authService.logout();
  }

}

