import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { Observable } from "rxjs/Observable";
import {
  Device,
  NfcUser,
  NfcBuilding,
  SlaAudit,
  NfcWaypoint,
  History,
  HistoryQuery,
  NfcUnknown
} from "../models/guardtour";  // was uppercase 'models/Guardtour'
import { map } from 'rxjs/operators';



@Injectable()
export class GuardtourService {

  

  deviceCollection: AngularFirestoreCollection<Device>;
  

  nfcUserCollection: AngularFirestoreCollection<NfcUser>;
  nfcBuildingCollection: AngularFirestoreCollection<NfcBuilding>;
  slaAuditCollection: AngularFirestoreCollection<SlaAudit>;
  nfcWaypointCollection: AngularFirestoreCollection<NfcWaypoint>;
  nfcUnknownCollection: AngularFirestoreCollection<NfcUnknown>;
  historyCollection: AngularFirestoreCollection<History>;

  devices: Observable<Device[]>;

  nfcUsers: Observable<NfcUser[]>;
  nfcBuildings: Observable<NfcBuilding[]>;
  slaAudit: Observable<SlaAudit[]>;
  nfcWaypoints: Observable<NfcWaypoint[]>;
  nfcUnknowns: Observable<NfcUnknown[]>;

  historys: Observable<History[]>;
  slaHistorys: Observable<SlaAudit[]>;

  fromMillis: HistoryQuery;
  toMillis: HistoryQuery;
  // dateFilter: String = "0"; // we may want maxDate minDate


  deviceDoc: AngularFirestoreDocument<Device>;
  
  

  nfcUserDoc: AngularFirestoreDocument<NfcUser>;
  nfcBuildingDoc: AngularFirestoreDocument<NfcBuilding>;
  slaAuditDoc: AngularFirestoreDocument<SlaAudit>;
  nfcWaypointDoc: AngularFirestoreDocument<NfcWaypoint>;
  nfcUnknownDoc: AngularFirestoreDocument<NfcUnknown>;

  historyDoc: AngularFirestoreDocument<History>;
  //  newHistoryDoc: AngularFirestoreDocument<History>;  //  used for migration  -- can be deleted after migrate
  //  newHistoryDocPath : String;


  constructor(public afs: AngularFirestore) {

    
    // this.afs.firestore.enablePersistence();


    ///// rks comment - Moved the below code into the getDevices function (due to observable not restarting on return to route)
    // this.devices = this.afs
    //   .collection("devices", ref => ref.orderBy("millis_update", "desc").limit(100) )
    //   .snapshotChanges()
    //   .map(changes => {
    //     return changes.map(a => {
    //       const data = a.payload.doc.data() as Device;
    //       data.id = a.payload.doc.id;
    //       return data;
    //     });
    //   });
  }

  getDevices() {
    this.devices = this.afs
      .collection("devices", ref =>
        ref.orderBy("millis_update", "desc").limit(100)
      )
      .snapshotChanges()
      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as Device;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as Device;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;

    return this.devices;
  }

  getNfcUsers() {
    this.nfcUsers = this.afs
      .collection("nfcUsers", ref => ref.orderBy("site", "asc").limit(100))
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as NfcUser;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as NfcUser;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.nfcUsers;
  }

  getNfcBuildings() {
    this.nfcBuildings = this.afs
      .collection("nfcBuildings", ref => ref.limit(1000))
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as NfcBuilding;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as NfcBuilding;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.nfcBuildings;
  }

  getSlaAudit() {
    const dateObj = new Date()
    const year = (dateObj.getUTCFullYear()).toString()
    const month = (dateObj.getUTCMonth() + 1).toString() //months from 1-12
    const day = (dateObj.getUTCDate()).toString()
    this.slaAudit = this.afs
      .collection("slaAudit").doc(year).collection(month, ref => ref.orderBy("created_at", "desc"))
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as SlaAudit;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     var data = a.payload.doc.data() as SlaAudit;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.slaAudit;
  }

  getNfcWaypoints() {
    this.nfcWaypoints = this.afs
      .collection("nfcWaypoints", ref =>
        ref.orderBy("millis_update", "desc").limit(100)
      )
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as NfcWaypoint;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as NfcWaypoint;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.nfcWaypoints;
  }

  getNfcUnknowns() {
    this.nfcUnknowns = this.afs
      .collection("nfcUnknowns")
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as NfcUnknown;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as NfcUnknown;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.nfcUnknowns;
  }





  getHistory(dateValue: Date) {
    // split incoming date into collection path e.g. history/2018_02/17
    const fullyear = dateValue.getFullYear().toString();  // a string e.g. "2018"
    const month = ("0" + (dateValue.getMonth() + 1)).slice(-2)   // a string e.g.  "02"
    const day = ("0" + dateValue.getDate()).slice(-2)   // a string e.g.  "17"
    const colPath = 'history/' + fullyear + '_' + month + '/' + day;

    


    var report_type = "millis_update"; //time

    //report_type = "p_tag"; // person
    //report_type = "nfc";  // site
    //report_type = "report" ; // report
    
    report_type = "millis_update"; //time



    this.historys = this.afs
      .collection(colPath, ref =>
        ref
          .orderBy(report_type, "desc") // time
          .limit(10000)  // Limit to 10000 records returned 
      )
      .snapshotChanges()

      .pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as History;
          data.id = a.payload.doc.id;
          return data;
        });
      }))

      // .map(changes => {
      //   return changes.map(a => {
      //     const data = a.payload.doc.data() as History;
      //     data.id = a.payload.doc.id;
      //     return data;
      //   });
      // })
      ;
    return this.historys;
  }

  getSlaHistory(dateValue: Date) {
    // split incoming date into collection path e.g. history/2018_02/17
    // should now be  slaAudit/2018/4
    const fullyear = dateValue.getFullYear().toString();  // a string e.g. "2018"
    const month = (dateValue.getMonth()) + 1 // a string e.g.  "2"
    const day = (dateValue.getDate().toString())   // a string e.g.  "17" or "3"
    const colPath = 'slaAudit/' + fullyear + '/' + month;
    var slaAuditDoc = this.afs.collection(colPath).doc(day);
    // var docSnapshotData: any
    var docArray = []
    var slaTargetAccumulator = 0
    var slaActualAccumulator = 0
    var overallPerformance = 0.0
    var promise1 = slaAuditDoc.ref.get().then(doc => {
      //console.log('Received doc snapshot3: ', doc.data());
      Object.keys(doc.data()).forEach((name) => {
        //console.log('snapshot7', name, doc.data()[name]);
        if (name != 'created_at') {
          docArray.push({ 'id': name, 'sla_target': doc.data()[name]['sla_target'], 'sla_actual': doc.data()[name]['sla_actual'], 'updated_at': doc.data()[name]['updated_at'], 'site': doc.data()[name]['site'] , 'comment': doc.data()[name]['comment'] })
          if (doc.data()[name]['sla_target']) {

            if (doc.data()[name]['sla_actual'] > doc.data()[name]['sla_target']){
              slaTargetAccumulator = slaTargetAccumulator + doc.data()[name]['sla_target']
              slaActualAccumulator = slaActualAccumulator + doc.data()[name]['sla_target']
            }else{
              slaTargetAccumulator = slaTargetAccumulator + doc.data()[name]['sla_target']
              slaActualAccumulator = slaActualAccumulator + doc.data()[name]['sla_actual']
            }

            // if (slaActualAccumulator > slaTargetAccumulator) {
            //   slaActualAccumulator = slaTargetAccumulator
            // }
          }
        }
      });
      overallPerformance = (slaActualAccumulator / slaTargetAccumulator)
      docArray.push({ 'id': 'Overall Performance', 'sla_target': '', 'sla_actual': slaActualAccumulator + ' / ' + slaTargetAccumulator + '  = ' +  Math.round(overallPerformance*1000)/10 + '%'    })
      console.log('docArray: ', docArray);
      return true
    })
    return docArray
  }


  deleteSlaItem(slaItem: SlaAudit) {
    this.slaAuditDoc = this.afs.doc(`slaAudit/${slaItem.id}`);
    this.slaAuditDoc.delete();
  }

  updateSlaItem( slaItem: SlaAudit) {

    this.slaAuditDoc = this.afs.doc(`slaAudit/${slaItem.id}`);
    this.slaAuditDoc.update(slaItem  );
  }



  deleteDevice(nfc: Device) {
    this.deviceDoc = this.afs.doc(`devices/${nfc.id}`);
    this.deviceDoc.delete();
  }

  updateDevice(nfc: Device) {
    this.deviceDoc = this.afs.doc(`devices/${nfc.id}`);
    this.deviceDoc.update(nfc);
  }

  deleteNfcUser(nfc: NfcUser) {
    this.deviceDoc = this.afs.doc(`nfcUsers/${nfc.id}`);
    this.deviceDoc.delete();
  }

  updateNfcUser(nfc: NfcUser) {
    this.deviceDoc = this.afs.doc(`nfcUsers/${nfc.id}`);
    this.deviceDoc.update(nfc);
  }

  deleteNfcBuilding(nfc: NfcBuilding) {
    this.deviceDoc = this.afs.doc(`nfcBuildings/${nfc.id}`);
    this.deviceDoc.delete();
  }

  updateNfcBuilding(nfc: NfcBuilding) {
    this.deviceDoc = this.afs.doc(`nfcBuildings/${nfc.id}`);
    this.deviceDoc.update(nfc);
  }

  deleteNfcWaypoint(nfc: NfcWaypoint) {
    this.deviceDoc = this.afs.doc(`nfcWaypoints/${nfc.id}`);
    this.deviceDoc.delete();
  }

  updateNfcWaypoint(nfc: NfcWaypoint) {
    this.deviceDoc = this.afs.doc(`nfcWaypoints/${nfc.id}`);
    this.deviceDoc.update(nfc);
  }

  deleteNfcUnknown(nfc: NfcUnknown) {
    this.deviceDoc = this.afs.doc(`nfcUnknowns/${nfc.id}`);
    this.deviceDoc.delete();
  }

  updateNfcUnknown(nfc: NfcUnknown) {
    this.deviceDoc = this.afs.doc(`nfcUnknowns/${nfc.id}`);
    this.deviceDoc.update(nfc);
  }

  deleteHistory(history: History) {
    this.historyDoc = this.afs.doc(`history/${history.id}`);
    this.historyDoc.delete();
  }

  updateHistory(history: History) {
    this.historyDoc = this.afs.doc(`history/${history.id}`);
    this.historyDoc.update(history);
  }

  reloadHistory(history: History) {
    this.historyDoc.update(history);
  }

}
