import { Component, OnInit } from "@angular/core";
import { GuardtourService } from "../../services/guardtour.service";
import { NfcBuilding } from "../../models/guardtour";
import { SlaAudit } from "../../models/guardtour";
import { ChartModule } from 'primeng/chart';

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"]
})
export class HomepageComponent implements OnInit {

  slaActual: number;
  slaTime: string;
  nfcs: NfcBuilding[];
  slas: SlaAudit[];
  editState: boolean = false;
  nfcToEdit: NfcBuilding;
  csvFile: string = "init"
  nfcRed: number = 0;
  nfcYellow: number = 0;
  nfcGreen: number = 0;
  nfcTotal: number = 0;
  slaRed: number = 0;
  slaYellow: number = 0;
  slaGreen: number = 0;
  slaTotal: number = 0;
  buildingsTotal: number = 0;
  //slaAudit: SlaAudit[]; // new
  latestStatsArray: any = [];
  slaData: any;
  dayToggle: boolean = true;

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {
    this.guardtourService.getNfcBuildings().subscribe(NfcBuilding => {
      //this.nfcs = NfcBuilding;  
      this.nfcs = NfcBuilding;
      // this.getSlaStats();
    });
    this.guardtourService.getSlaAudit().subscribe(SlaAudit => {   //  <<<<<<<<<<<<<<<<<<<<<<  need to fix
      this.slas = SlaAudit;
      this.getSlaAuditStats();
      this.buildCsv()
    });

  }

  toggleDay() {
    this.dayToggle = !this.dayToggle
    const qqq = this.getSlaAuditStats() 
    return this.dayToggle
  }

  buildCsv() {

      var fields = Object.keys(this.slas[0])

    // delete fields['millis_update']  // broken ??
    // delete fields['millis_create']  // broken ??
    // delete fields[0] // this works !

    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = this.slas.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join('\t')
    })
    csv.unshift(fields.join('\t')) // add header column
    // console.log(csv.join('\r\n'))
    this.csvFile = csv.join('\r\n')
    return "data:application/octet-stream," + encodeURIComponent(csv.join('\r\n'))
  }

  downloadCsv() {
    var d = new Date()
    var n = d.getTime().toString()
    var textToSave = this.csvFile
    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:attachment/text,' + encodeURI(textToSave)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'guardtour ' + n + '.csv'
    hiddenElement.click()
  }


  getSlaAuditStats() {
    if (this.dayToggle) {
      var latestStats = this.slas[0]  //  today.
    } else {
      var latestStats = this.slas[1]  //  yesterday.
    }

    const dateObj = new Date()
    const month = dateObj.getUTCMonth() + 1 //months from 1-12
    console.log('Debug - ', dateObj)
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()

    this.latestStatsArray = [];  //  empty our array

    if (latestStats == null) {
      console.log('No data exists for this month')
      return false // get out if no stats exist
    }

    var slaRed = 0;
    var slaYellow = 0;
    var slaGreen = 0;
    var slaTotal = 0; 

    for (var key in latestStats) {
      if (latestStats.hasOwnProperty(key) && key.charAt(0) == 'B') { // only consider Buildings
        let actual = latestStats[key]['sla_actual']
        let target = latestStats[key]['sla_target']
        let site = latestStats[key]['site']
        let updatedTimestamp = latestStats[key]['updated_at'].toDate()
        let date = (updatedTimestamp.getUTCDate() < 10 ? "0" : "") + updatedTimestamp.getUTCDate() // The getUTCDate() method returns the day of the month (from 1 to 31) of the date object, according to universal time
        let month = ((updatedTimestamp.getUTCMonth() + 1) < 10 ? "0" : "") + (updatedTimestamp.getUTCMonth() + 1)
        let hours = (updatedTimestamp.getHours() < 10 ? "0" : "") + updatedTimestamp.getHours()
        let minute = (updatedTimestamp.getMinutes() < 10 ? "0" : "") + updatedTimestamp.getMinutes()

        let updatedAt: string = date + "/" + month + " - " + hours + ":" + minute
        let updatedMillis = latestStats[key]['updated_millis']

        this.latestStatsArray.push({ 'id': key, 'sla_actual': actual, 'sla_target': target, 'updated_at': updatedAt, 'timestamp': updatedTimestamp, 'site': site })

        //console.log('debug 4 >>>>>>>>> ', key, actual, target, updatedAt, updatedMillis, this.nfcs.length );
        // this gives e.g.  BCSG1357   0   2   Mon Apr 23 2018 00:02:12 GMT+0100 (GMT Daylight Time)   1524438132856   167

        if (target > 0) {
          // SLA is set
          if (actual == 0) {
            slaRed++; // 0 gets a red
          } else {
            if (actual >= target) {
              // SLA met
              slaGreen++;
            } else {
              // SLA not met
              slaYellow++;
            }
          }
        } else {
          slaGreen++; // No SLA gets counted as Green
        }
      }
    }

    this.slaRed = slaRed;
    this.slaYellow = slaYellow;
    this.slaGreen = slaGreen;
    this.slaTotal = slaRed + slaYellow + slaGreen;
    this.buildingsTotal = this.nfcs.length

  }
}


