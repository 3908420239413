import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service"; // see https://alligator.io/angular/firebase-authentication-angularfire2/
import { Router, Route, RouterModule } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit() {

  }

  signup() {
    this.authService.signup(this.email, this.password);
    this.email = this.password = '';
  }

  login () {
    this.authService.login(this.email, this.password).subscribe(() => {
     this.router.navigate(['/']) ; 
    } )
    this.email = this.password = ''
  }

  logout() {
    this.authService.logout();
  } 
}
