import { Component, OnInit } from '@angular/core';
import { GuardtourService } from '../../services/guardtour.service';
import { NfcWaypoint } from '../../models/guardtour';

@Component({
  selector: 'app-nfcwaypoints',
  templateUrl: './nfcwaypoints.component.html',
  styleUrls: ['./nfcwaypoints.component.css']
})

export class NfcwaypointsComponent implements OnInit {
  nfcs: NfcWaypoint[];
  editState: boolean = false;
  nfcToEdit: NfcWaypoint;

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {
        this.guardtourService.getNfcWaypoints().subscribe(NfcWaypoint=> {
      //console.log(devices);
      this.nfcs = NfcWaypoint;
    });

  }

 deleteNfc(event, nfc: NfcWaypoint) {
    this.clearState();
    this.guardtourService.deleteNfcWaypoint(nfc);
  }

  editNfc(event, nfc: NfcWaypoint) {
    this.editState = true;
    this.nfcToEdit = nfc;
  }

  updateNfc(nfc: NfcWaypoint) {
    this.guardtourService.updateNfcWaypoint(nfc);
    this.clearState();
    
  }

  clearState() {
    this.editState = false;
    this.nfcToEdit = null;
  }






}
