import { Component, OnInit } from "@angular/core";
import { GuardtourService } from "../../services/guardtour.service";
import { History } from "../../models/guardtour";
// import { LeafletModule } from "@asymmetrik/angular2-leaflet";
// import * as L from "leaflet";

@Component({
  selector: "app-historys",
  templateUrl: "./historys.component.html",
  styleUrls: ["./historys.component.css"]
})

export class HistorysComponent implements OnInit {
  csvFile: string;
  uriContent: string = "init";
  historys: History[];
  editState: boolean = false;
  historyToEdit: History;
  historyRange: Date[];
  dateValue: Date;   ////////////////
  historyTo: Date;
  historyFrom: Date;
  options: any; // this is for leaflet

  sortType = [
    {name: 'Timestamp', code: 'millis_update'},
    {name: 'Report', code: 'report'},
    {name: 'User', code: 'p_tag'},
    {name: 'NFC', code: 'nfc'} ];

    

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {
    // this.getFromTo([new Date()]); // rks - needed!
    this.getHistory(new Date() );

  }

  deleteHistory(event, history: History) {
    this.clearState();
    this.guardtourService.deleteHistory(history);
  }

  editHistory(event, history: History) {
    this.editState = true;
    this.historyToEdit = history;
  }

  updateHistory(history: History) {
    this.guardtourService.updateHistory(history);
    this.clearState();
  }

  getMiniDeviceId(history: History) {
    return history.device.substr(24, 12);
  }

  googleMap(lat, lon) {
    const key = "AIzaSyC_8p4GeZRsHHJ2wb7WY7C4Wy_pC7LjZUI"
    var url = "https://maps.googleapis.com/maps/api/staticmap?center=" + lat + "," + lon + "&zoom=16&size=400x400&key=" + key
    return url

  }

  buildCsv() {
    // console.log("buildCSV ran... ")
    var fields = Object.keys(this.historys[0])

    // delete fields[0]  // AGLIFE_2147_T2	
    // delete fields[1]  // action	
    // delete fields[2]  // device	
    // delete fields[3]  // device_name	
    // delete fields[4]  // latitude	
    // delete fields[5]  // location	
    // delete fields[6]  // longitude	
    // delete fields[7]  // millis_create	
    // delete fields[8]  // millis_update	
    // delete fields[9]  // nfc	
    // delete fields[10]  // nfc_serial	
    // delete fields[11]  // p_tag	
    // where is report ??????
    // delete fields[12]  // status	
    // delete fields[13]  // timestamp	
    // delete fields[14]  // id

    //fields = [fields[13],fields[9],fields[3],fields[1],fields[4],fields[6],fields[11]]
    fields = ['timestamp','nfc','device_name','p_tag','action','report','latitude','longitude']

    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = this.historys.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join('\t')
    })
    csv.unshift(fields.join('\t')) // add header column
    // console.log(csv.join('\r\n'))
    this.csvFile = csv.join('\r\n')
    return "data:application/octet-stream," + encodeURIComponent(csv.join('\r\n'))
  }

  downloadCsv() {
    var d = new Date()
    var n = d.getTime().toString()
    var textToSave = this.csvFile
    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:attachment/text,' + encodeURI(textToSave)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'guardtour ' + n + '.csv'
    hiddenElement.click()
  }

  clearState() {
    this.editState = false;
    this.historyToEdit = null;
  }

  getHistory(dateValue) {
    if (dateValue == null) {
      dateValue = new Date();
    }

  

    this.guardtourService
      .getHistory(dateValue)
      .subscribe(historys => {
        this.historys = historys
        this.uriContent = this.buildCsv()
      })
  }

}
