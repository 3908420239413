import { Component, OnInit } from '@angular/core';
import { GuardtourService } from '../../services/guardtour.service';
import { NfcUnknown} from '../../models/guardtour';

@Component({
  selector: 'app-nfcunknowns',
  templateUrl: './nfcunknowns.component.html',
  styleUrls: ['./nfcunknowns.component.css']
})

export class NfcunknownsComponent implements OnInit {
  nfcs: NfcUnknown[];
  editState: boolean = false;
  nfcToEdit: NfcUnknown;

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {
        this.guardtourService.getNfcUnknowns().subscribe(NfcUnknown=> {
      //console.log(devices);
      this.nfcs = NfcUnknown;
    });

  }


 deleteNfc(event, nfc: NfcUnknown) {
    this.clearState();
    this.guardtourService.deleteNfcUnknown(nfc);
  }

  editNfc(event, nfc: NfcUnknown) {
    this.editState = true;
    this.nfcToEdit = nfc;
  }

  updateNfc(nfc: NfcUnknown) {
    this.guardtourService.updateNfcUnknown(nfc);
    this.clearState();
    
  }

  clearState() {
    this.editState = false;
    this.nfcToEdit = null;
  }



}
