export const environment = {
  production: true,
    firebase: {
    apiKey: "AIzaSyA7dZA3J8SbsBnyYbhiu-RYgGHMX3Mb4oA",
    authDomain: "csg-guardtour-2.firebaseapp.com",
    databaseURL: "https://csg-guardtour-2.firebaseio.com",
    projectId: "csg-guardtour-2",
    storageBucket: "csg-guardtour-2.appspot.com",
    messagingSenderId: "436270852701"
    }
};
