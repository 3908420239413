import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service"; // see https://alligator.io/angular/firebase-authentication-angularfire2/
import { FlashMessagesService } from 'angular2-flash-messages';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public authService: AuthService,
  public flashMessage: FlashMessagesService) { }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
    console.log('logout ran');
    this.flashMessage.show('You are now logged out', {cssClass: ' green lighten-2', timeout: 3000 }); 
  }

}
