
// see https://alligator.io/angular/firebase-authentication-angularfire2/

import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {
  user: Observable<firebase.User>;

  constructor(private firebaseAuth: AngularFireAuth) {
    this.user = firebaseAuth.authState;
  }

  signup(email: string, password: string) {
    this.firebaseAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });    
  }

  login(email: string, password: string) {
    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        console.log('User ' + email + ' logged on');

      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });
      return  this.firebaseAuth.authState
  }

  logout() {
    this.firebaseAuth
      .auth
      .signOut();
  }

  authenticated() {
    return this.firebaseAuth.auth.currentUser !== null;
  };



}