import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { DataTableModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator'; 
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { LightboxModule } from 'primeng/lightbox';

//import { environment } from '../environments/environment';
import { environment } from '../environments/environment.prod';
import { AngularFireModule } from '@angular/fire'; 
import { AngularFireAuthModule } from '@angular/fire/auth';                                            
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthService } from './services/auth.service';

// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { ItemsComponent } from './components/items/items.component';
import { ItemService } from './services/item.service';
import { DevicesComponent } from './components/devices/devices.component';
// import { NfcsComponent } from './components/nfcs/nfcs.component';
import { HistorysComponent } from './components/historys/historys.component';
import { GuardtourService } from './services/guardtour.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddItemComponent } from './components/add-item/add-item.component';
import { RkstestComponent } from './components/rkstest/rkstest.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NfcusersComponent } from './components/nfcusers/nfcusers.component';
import { NfcbuildingsComponent } from './components/nfcbuildings/nfcbuildings.component';
import { NfcwaypointsComponent } from './components/nfcwaypoints/nfcwaypoints.component';
import { NfcunknownsComponent } from './components/nfcunknowns/nfcunknowns.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

import { MapComponent } from './components/map/map.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { AuthGuard } from './auth.guard';
import { SlaReportComponent } from './components/sla-report/sla-report.component';


const appRoutes: Routes = [
  { path:'', component:HomepageComponent, canActivate: [AuthGuard] },
  { path:'history', component:HistorysComponent, canActivate: [AuthGuard] },
  { path:'devices', component:DevicesComponent, canActivate: [AuthGuard] },
  { path:'nfcusers', component:NfcusersComponent, canActivate: [AuthGuard] },
  { path:'nfcbuildings', component:NfcbuildingsComponent, canActivate: [AuthGuard] },
  { path:'nfcwaypoints', component:NfcwaypointsComponent, canActivate: [AuthGuard] },
  { path:'nfcunknowns', component:NfcunknownsComponent, canActivate: [AuthGuard] },
  { path:'login', component:LoginComponent },
  // { path:'register', component:RegisterComponent },
  { path:'map', component:MapComponent, canActivate: [AuthGuard] },
  { path:'sla-report', component:SlaReportComponent, canActivate: [AuthGuard] },
  // { path:'items', component:ItemsComponent, canActivate: [AuthGuard] },
  // { path:'rkstest', component:RkstestComponent }

];



@NgModule({
  declarations: [
    AppComponent,
    ItemsComponent,
    DevicesComponent,
    HistorysComponent,
    NavbarComponent,
    AddItemComponent,
    RkstestComponent,
    HomepageComponent,
    NfcusersComponent,
    NfcbuildingsComponent,
    NfcwaypointsComponent,
    NfcunknownsComponent,
    LoginComponent,
    RegisterComponent,
    MapComponent,
    SlaReportComponent
  ],
  imports: [
    AngularFirestoreModule.enablePersistence(),
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase, 'csg-guardtour-2'),
    AngularFireAuthModule,
    RouterModule.forRoot(appRoutes),
    // LeafletModule.forRoot(),
    TableModule,
    PaginatorModule,
    CalendarModule,
    ChartModule,
    LightboxModule,
    BrowserAnimationsModule,
    FlashMessagesModule.forRoot()
  ],
  providers: [ItemService, GuardtourService, AuthService, AuthGuard ],

  bootstrap: [AppComponent]
})
export class AppModule { 

}
