import { Component, OnInit } from "@angular/core";
import { GuardtourService } from "../../services/guardtour.service";
import { NfcUser } from "../../models/guardtour";

@Component({
  selector: "app-nfcusers",
  templateUrl: "./nfcusers.component.html",
  styleUrls: ["./nfcusers.component.css"]
})
export class NfcusersComponent implements OnInit {
  nfcs: NfcUser[];
  editState: boolean = false;
  nfcToEdit: NfcUser;

  constructor(private guardtourService: GuardtourService) {}

  ngOnInit() {
    this.guardtourService.getNfcUsers().subscribe(nfcUsers => {
      //console.log(nfcUsers);
      this.nfcs = nfcUsers;
    });

    {
      console.log("init ran");
    }

  }

  deleteNfc(event, nfc: NfcUser) {
    this.clearState();
    this.guardtourService.deleteNfcUser(nfc);
  }

  editNfc(event, nfc: NfcUser) {
    this.editState = true;
    this.nfcToEdit = nfc;
  }

  updateNfc(nfc: NfcUser) {
    this.guardtourService.updateNfcUser(nfc);
    this.clearState();
  }

  clearState() {
    this.editState = false;
    this.nfcToEdit = null;
  }
}
