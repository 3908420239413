import { Component, OnInit } from '@angular/core';
import { GuardtourService } from '../../services/guardtour.service';
import { NfcBuilding} from '../../models/guardtour';

@Component({
  selector: 'app-nfcbuildings',
  templateUrl: './nfcbuildings.component.html',
  styleUrls: ['./nfcbuildings.component.css']
})

export class NfcbuildingsComponent implements OnInit {
  nfcs: NfcBuilding[];
  editState: boolean = false;
  nfcToEdit: NfcBuilding;

  constructor(private guardtourService: GuardtourService) { }

  ngOnInit() {
        this.guardtourService.getNfcBuildings().subscribe(NfcBuilding=> {
      //console.log(devices);
      this.nfcs = NfcBuilding;
    });

  }


 deleteNfc(event, nfc: NfcBuilding) {
    this.clearState();
    this.guardtourService.deleteNfcBuilding(nfc);
  }

  editNfc(event, nfc: NfcBuilding) {
    this.editState = true;
    this.nfcToEdit = nfc;
  }

  updateNfc(nfc: NfcBuilding) {
    this.guardtourService.updateNfcBuilding(nfc);
    this.clearState();
    
  }

  clearState() {
    this.editState = false;
    this.nfcToEdit = null;
  }




}
